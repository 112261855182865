<template>
  <div class="header-wishlist">
    <b-button id="HeaderWishlistButton" class="counter-wrapper" variant="outline-control" size="xs" v-bind="$attrs" v-on="$listeners">
      <lazy-icon icon="heart"/>
      <counter :count="itemCount"/>
    </b-button>

    <b-popover
      v-if="lastAction.type"
      target="HeaderWishlistButton"
      container="HeaderWishlistButton"
      placement="bottomleft"
      triggers="manual"
      :show="popover.show"
    >
      <i18n tag="p" :path="`${tPath}.popover.actiontype.${lastAction.type.toLowerCase()}`">
        <template v-for="(prop, pKey) in popoverActiontypeProperties" #[pKey]>
          <strong :key="pKey">{{ prop }}</strong>
        </template>
      </i18n>
      <b-button :href="$attrs.href" variant="primary" size="xs" block>{{ $t(`${tPath}.popover.basketbutton`) }}</b-button>
    </b-popover>
  </div>
</template>

<script>
import { COMPONENT_HEADERWISHLIST_POPOVER_HIDE_TIMEOUT } from '@/constants'

import { flatten } from '@/assets/js/helper/object'

import Counter from '@/components/private/Counter'

export default {
  name: 'HeaderWishlist',
  inheritAttrs: false,
  components: {
    Counter
  },
  data () {
    return {
      popover: {
        show: false,
        timeout: null
      }
    }
  },
  computed: {
    itemCount () {
      return this.$store.getters['wishlist/getItemCount']
    },
    lastAction () {
      return this.$store.getters['wishlist/getLastAction']
    },
    popoverActiontypeProperties () {
      return flatten(this.lastAction)
    }
  },
  methods: {
    showPopover () {
      if (this.lastAction.type) {
        if (this.popover.timeout) clearTimeout(this.popover.timeout)

        this.popover.show = true
        this.popover.timeout = setTimeout(() => {
          this.popover.show = false
        }, COMPONENT_HEADERWISHLIST_POPOVER_HIDE_TIMEOUT)
      } else {
        if (this.popover.timeout) clearTimeout(this.popover.timeout)
        this.popover.show = false
      }
    }
  },
  created () {
    this.$store.dispatch('wishlist/getMinimal')
  },
  watch: {
    lastAction () {
      this.showPopover()
    }
  }
}
</script>

<style lang="scss">
$headerwishlist-popover-min-width: 250px !default;

.header-wishlist {
  #HeaderWishlistButton {
    &:not(:hover):not(:active) {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .popover {
    min-width: $headerwishlist-popover-min-width;

    .arrow {
      // icon font-size = 125%, defined by bootstrap-vue
      // 1.25em / 2 = 0.625em
      margin-left: calc($btn-border-width + $btn-padding-x-xs + 0.625em - ($popover-arrow-width * 0.5));
      margin-right: calc($btn-border-width + $btn-padding-x-xs + 0.625em - ($popover-arrow-width * 0.5));
    }
  }
}
</style>
