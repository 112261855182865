<template>
  <transition-expand
    name="headerbanner"
    :minHeight="0"
    @enter="updateScrollPos"
    @leave="updateScrollPos"
  >
    <div v-if="show" :class="['header-banner', { [`bg-${variant}`]: variant, 'text-white': variant }]">
      <div class="container">
        <slot/>

        <a class="banner-close" href="#" @click.prevent="close"><lazy-icon icon="remove"/></a>
      </div>
    </div>
  </transition-expand>
</template>

<script>
import { LOCALE_STORAGE_KEYS } from '@/constants'
import TransitionExpand from '@/components/private/TransitionExpand'

const INITIALIZE_TIMEOUT = 1500

export default {
  name: 'HeaderBanner',
  components: {
    TransitionExpand
  },
  props: {
    name: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      localStorageItem: LOCALE_STORAGE_KEYS.headerbanner.replace('{bannername}', this.name),
      show: false
    }
  },
  methods: {
    close () {
      window.localStorage.setItem(this.localStorageItem, false)
      this.show = JSON.parse(window.localStorage.getItem(this.localStorageItem))
    },
    updateScrollPos () {
      window.dispatchEvent(new Event('scroll'))
    }
  },
  created () {
    setTimeout(() => {
      const storeItem = JSON.parse(window.localStorage.getItem(this.localStorageItem))
      this.show = this.$root.isBoolean(storeItem) ? storeItem : true
    }, INITIALIZE_TIMEOUT)
  }
}
</script>

<style lang="scss">
// mobile
$headerbanner-padding-y: $spacer * 0.6125 !default;
$headerbanner-padding-x: $spacer * 1.5 !default;

$headerbanner-close-offset-y: calc(($headerbanner-padding-x - 1em) / 2) !default;
$headerbanner-close-offset-x: $headerbanner-close-offset-y !default;

$headerbanner-letter-spacing: $letter-spacing-2 !default;

$headerbanner-transition: height ease-out 600ms !default;

// desktop
$headerbanner-desktop: $desktop-breakpoint !default;

$headerbanner-desktop-padding-y: $spacer * 1.25 !default;
$headerbanner-desktop-padding-x: $spacer * 2 !default;

$headerbanner-desktop-letter-spacing: $headerbanner-letter-spacing !default;

$headerbanner-desktop-close-offset-y: calc(50% - 0.5em) !default;
$headerbanner-desktop-close-offset-x: calc(($headerbanner-desktop-padding-x - 1em) / 2) !default;

.header-banner {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: $headerbanner-padding-y $headerbanner-padding-x;
  text-align: center;

  > .container {
    position: relative;
    padding: 0;
    letter-spacing: $headerbanner-letter-spacing;

    a{
      color: inherit;
      text-decoration: underline;
    }

    .banner-close {
      display: block;
      position: absolute;
      bottom: calc(100% - 1em + $headerbanner-close-offset-y);
      left: calc(100% + $headerbanner-close-offset-x);
      line-height: 1;
      color: inherit;
    }
  }

  &.headerbanner-enter-active,
  &.headerbanner-leave-active {
    transition: $headerbanner-transition;
  }

  @include media-breakpoint-up($headerbanner-desktop) {
    position: static;
    padding: 0;

    > .container {
      padding: $headerbanner-desktop-padding-y $headerbanner-desktop-padding-x;
      letter-spacing: $headerbanner-desktop-letter-spacing;

      .banner-close {
        bottom: auto;
        top: $headerbanner-desktop-close-offset-y;
        left: auto;
        right: $headerbanner-desktop-close-offset-x;
      }
    }
  }
}
</style>
