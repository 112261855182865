<template functional>
  <div :id="data.id" :class="['header-user-menu', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs" role="menu">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'HeaderUserMenu'
}
</script>

<style lang="scss">
$headerusermenu-gap: $spacer * 0.5 !default;
$headerusermenu-item-gutter: $spacer * 0.6 !default;

.header-user-menu {
  display: flex;
  margin: $headerusermenu-gap ($headerusermenu-item-gutter * -0.5);

  > * {
    margin-left: $headerusermenu-item-gutter * 0.5;
    margin-right: $headerusermenu-item-gutter * 0.5;
  }
}
</style>
