<template>
  <div
    :class="['dragger', { 'is-draggable': isDraggable, 'is-dragging': isDragging }]"
    @mouseenter="init"
    @mousedown="start"
    @wheel="scroll"
  >
    <slot/>
  </div>
</template>

<script>
const SCROLL_THROTTLE = 0.5

export default {
  name: 'Dragger',
  props: {
    alignV: {
      type: String,
      default: 'top'
    },
    alignH: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      setInitialScrollPositions: true,
      isDraggable: false,
      isDragging: false,
      y: null,
      x: null,
      t: null,
      l: null
    }
  },
  methods: {
    init () {
      const domData = this.getDomData()

      this.isDraggable = domData.outer.width < domData.inner.width || domData.outer.width < domData.inner.height
      this.$el.scrollTop = domData.scrollTop
      this.$el.scrollLeft = domData.scrollLeft

      this.$emit('dragger:init')
    },
    start (e) {
      if (this.isDraggable && !this.$root.env.isTouch) {
        this.isDragging = true
        this.y = e.pageY - this.$el.offsetTop
        this.x = e.pageX - this.$el.offsetLeft
        this.t = this.$el.scrollTop
        this.l = this.$el.scrollLeft

        this.$emit('dragger:start')
      }
    },
    drag (e) {
      if (this.isDraggable && this.isDragging) {
        const scrollY = this.t - (e.pageY - this.$el.offsetTop - this.y)
        const scrollX = this.l - (e.pageX - this.$el.offsetLeft - this.x)
        const scrollPosChange = scrollY !== this.$el.scrollTop || scrollX !== this.$el.scrollLeft

        this.$el.scrollTop = scrollY
        this.$el.scrollLeft = scrollX

        if (scrollPosChange) this.$emit('dragger:drag')
      }
    },
    end (e) {
      if (this.isDraggable && this.isDragging) {
        this.isDragging = false
        this.y = null
        this.x = null
        this.t = null
        this.l = null

        this.setInitialScrollPositions = false

        setTimeout(() => {
          this.$emit('dragger:end')
        }, 200)
      }
    },
    scroll (e) {
      if (e.shiftKey) {
        this.$el.scrollTop += e.deltaX * SCROLL_THROTTLE
        this.$el.scrollLeft += e.deltaY * SCROLL_THROTTLE
      } else {
        this.$el.scrollTop += e.deltaY * SCROLL_THROTTLE
        this.$el.scrollLeft += e.deltaX * SCROLL_THROTTLE
      }

      this.setInitialScrollPositions = false

      this.$emit('dragger:scroll')
    },
    reinit () {
      this.setInitialScrollPositions = true
      this.init()
    },
    getDomData () {
      this.$el.style.overflow = 'hidden'

      const innerEl = this.$el.querySelector('*')
      const outer = this.$el.getBoundingClientRect()
      const activeScrollTop = this.$el.scrollTop
      const activeScrollLeft = this.$el.scrollLeft

      this.$el.style.overflow = null
      innerEl.style.position = 'absolute'

      const inner = innerEl.getBoundingClientRect()
      const initialScrollTop = this.alignV === 'top' ? 0 : inner.height - outer.height
      const initialScrollLeft = this.alignH === 'left' ? 0 : inner.width - outer.width

      innerEl.style.position = null

      return {
        outer,
        inner,
        scrollTop: this.setInitialScrollPositions ? initialScrollTop : activeScrollTop,
        scrollLeft: this.setInitialScrollPositions ? initialScrollLeft : activeScrollLeft
      }
    }
  },
  mounted () {
    this.$root.$on('dragger:reinit', this.reinit)
    window.addEventListener('resize', this.reinit)
    document.documentElement.addEventListener('mousemove', this.drag)
    document.documentElement.addEventListener('mouseup', this.end)

    this.$nextTick(this.init)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reinit)
    document.documentElement.removeEventListener('mousemove', this.drag)
    document.documentElement.removeEventListener('mouseup', this.end)
  }
}
</script>

<style lang="scss">
.dragger {
  width: 100%;

  &.is-draggable {
    overflow: hidden;
    cursor: grab;
    user-select: none;

    &.is-dragging {
      cursor: grabbing;

      * {
        cursor: inherit!important;
      }
    }
  }

  .is-touch & {
    overflow: auto;
    cursor: default;
    user-select: initial;
  }
}
</style>
