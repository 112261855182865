<template>
  <transition
    :name="name"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    v-on="$listeners"
  >
    <slot v-bind="{ isOverflowing }" />
  </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'expand'
    },
    minHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isOverflowing: true,
      state: this.expanded,
      singleElement: this.minHeight > 0,
      closeHeight: `${this.minHeight}px`,
      repaintHeight: 0,
      classes: {
        state: {
          open: `${this.name}-state-open`,
          closed: `${this.name}-state-closed`
        },
        enter: {
          from: `${this.name}-enter`,
          active: `${this.name}-enter-active`,
          to: `${this.name}-enter-to`
        },
        leave: {
          from: `${this.name}-leave`,
          active: `${this.name}-leave-active`,
          to: `${this.name}-leave-to`
        }
      }
    }
  },
  methods: {
    calcIsOverflowing(element = {}) {
      if (element.style) {
        const styles = {
          width: element.style.width,
          position: element.style.position,
          visibility: element.style.visibility,
          height: element.style.height
        }

        element.style.width = getComputedStyle(element).width
        element.style.position = 'absolute'
        element.style.visibility = 'hidden'
        element.style.height = 'auto'

        const collapsedHeight = this.minHeight
        const expandedHeight = parseInt(getComputedStyle(element).height)

        element.style.width = styles.width
        element.style.position = styles.position
        element.style.visibility = styles.visibility
        element.style.height = styles.height

        this.isOverflowing = expandedHeight > collapsedHeight
        this.closeHeight = this.isOverflowing ? `${this.minHeight}px` : 'auto'

        if (!this.state) element.style.height = this.closeHeight
      }
    },
    beforeEnter(element) {
      this.state = !this.state

      if (this.singleElement) {
        element.classList.remove(this.classes.state.open, this.classes.state.closed)

        if (!this.state) {
          element.classList.remove(this.classes.enter.from)
          element.classList.add(this.classes.leave.from)
        }
      }
    },
    enter(element) {
      if (this.singleElement && !this.state) {
        element.classList.remove(this.classes.enter.active)
        element.classList.add(this.classes.leave.active)
      }

      element.style.width = getComputedStyle(element).width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null

      if (this.singleElement) {
        element.style.height = this.state ? this.closeHeight : height
      } else {
        element.style.height = 0
      }

      // Force repaint to make sure the
      // animation is triggered correctly.
      this.repaintHeight = getComputedStyle(element).height

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        if (this.singleElement && !this.state) {
          element.style.height = this.state ? height : this.closeHeight

          requestAnimationFrame(() => {
            element.classList.remove(this.classes.enter.to)
            element.classList.add(this.classes.leave.to)
            element.classList.remove(this.classes.leave.from)
          })
        } else {
          element.style.height = height
        }
      })
    },
    afterEnter(element) {
      if (this.singleElement) {
        element.classList.add(this.state ? this.classes.state.open : this.classes.state.closed)

        if (this.state) {
          element.style.overflow = null
          element.style.height = 'auto'
        } else {
          element.style.overflow = 'hidden'
          element.style.height = this.closeHeight
          element.classList.remove(this.classes.leave.active, this.classes.leave.to)
        }
      } else {
        element.style.height = 'auto'
      }
    },
    beforeLeave(element) {
      if (this.singleElement) {
        element.style.display = 'none'
      }
    },
    leave(element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      // Force repaint to make sure the
      // animation is triggered correctly.
      this.repaintHeight = getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = this.closeHeight
      })
    }
  },
  mounted() {
    if (this.singleElement) {
      this.afterEnter(this.$el)
      this.calcIsOverflowing(this.$el)
    }
  },
  watch: {
    '$root.mobilefirstBreakpoint.key'() {
      this.calcIsOverflowing(this.$el)
    }
  }
}
</script>
