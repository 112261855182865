<template>
  <div class="header-account">
    <template v-if="isAuthenticated">
      <div class="dropdown" @click.stop>
        <button
          class="btn btn-outline-control btn-xs dropdown-toggle"
          :class="{ 'dropdown-toggle-no-caret': !$root.isDesktop }"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="toggle()"
        >
          <lazy-icon icon="user-circle" /><span>{{ userName }}</span>
        </button>

        <c-account-menu
          v-if="state"
          :menu="menu"
          :logout="logout"
          :translations="translations"
          :style="{ top: !$root.isDesktop ? `${$root.header.inViewportHeight}px` : 'auto' }"
        >
          <template v-if="!$root.isDesktop" #menuClose>
            <a href="#" @click="toggle(false)"><lazy-icon icon="remove" /></a>
          </template>
        </c-account-menu>
      </div>
    </template>

    <a v-else class="btn btn-outline-control btn-xs" :href="login.href" :rel="login.rel">
      <lazy-icon icon="user-circle" /><span>{{ login.text }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HeaderAccount',
  props: {
    login: {
      type: Object,
      default: () => ({})
    },
    logout: {
      type: Object,
      default: () => ({})
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      state: false
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['configuration/getIsAuthenticated']
    },
    profile() {
      return this.$store.getters['profile/get']
    },
    userName() {
      const profile = this.profile
      if (profile.user.firstname && profile.user.lastname) {
        return `${profile.user.firstname} ${profile.user.lastname}`
      }

      return profile.user.userName
    }
  },
  methods: {
    toggle(state = null) {
      this.state = state !== null ? state : !this.state
      this.$root.$emit('scrollbar:toggle', { key: this.$options.name, state: this.state, from: 0, till: this.$root.md.value })
    },
    clickOutsideHandler() {
      if (this.$root.isDesktop) {
        this.toggle(false)
      }
    }
  },
  created() {
    window.addEventListener('click', this.clickOutsideHandler)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutsideHandler)
  }
}
</script>

<style lang="scss">
// mobile
$headeraccount-accountmenu-box-shadow: map-get($shadows, 'header') !default;

// desktop
$headeraccount-desktop: $desktop-breakpoint !default;

$headeraccount-desktop-btn-max-width: 250px !default;
$headeraccount-desktop-btn-icon-gap: $spacer * 0.25 !default;

$headeraccount-desktop-dropdown-menu-box-shadow: map-get($shadows, 'dropdown') !default;

.header-account {
  .dropdown {
    .dropdown-menu {
      display: none !important;
    }
  }

  @include media-breakpoint-down(get-breakpoint-key($headeraccount-desktop, -1)) {
    .btn {
      &:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }

      > span {
        @include sr-only();
      }
    }

    .dropdown {
      .dropdown-toggle {
        position: relative;

        .toggle-handler {
          position: absolute;
          inset: 0;
        }
      }

      .account-menu {
        position: fixed;
        inset: 0;
        overflow: hidden;
        overflow-y: auto;

        > div {
          box-shadow: $headeraccount-accountmenu-box-shadow, inset $headeraccount-accountmenu-box-shadow;
        }
      }
    }
  }

  @include media-breakpoint-up($headeraccount-desktop) {
    position: relative;

    .btn {
      display: flex;
      align-items: center;
      max-width: $headeraccount-desktop-btn-max-width;

      > span {
        display: block;
        overflow: hidden;
        margin-left: $headeraccount-desktop-btn-icon-gap;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .dropdown {
      .account-menu {
        position: absolute;
        top: 100%;
        right: 0;
        float: left;
        margin-top: $dropdown-spacer;
        width: auto;
        min-width: $dropdown-min-width * 1.5;
        border: $dropdown-border-width solid $dropdown-border-color;
        border-radius: $dropdown-border-radius;
        box-shadow: $headeraccount-desktop-dropdown-menu-box-shadow;
      }
    }
  }
}
</style>
