<template>
  <b-dropdown
    :class="['languagenavigation', { 'is-static': isStatic }]"
    right
    v-bind="dropdownOptions"
    :no-caret="!$root.isDesktop && !isStatic"
  >
    <template #button-content>{{ activeCulture.text }}</template>

    <b-dropdown-header v-if="!$root.isDesktop && !isStatic" tag="div">{{ $t('headerlanguagenavigation.header') }}</b-dropdown-header>

    <b-dropdown-item
      v-for="culture in cultures"
      :key="culture.href"
      :href="culture.href"
      :active="culture.active"
    >{{ culture.text }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'HeaderLanguagenavigation',
  props: {
    cultures: {
      type: Array,
      required: true
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dropdownOptions () {
      if (!this.$root.isDesktop && !this.isStatic) {
        return {
          variant: 'default',
          popperOpts: {
            modifiers: {
              applyStyle: { enabled: false }
            }
          }
        }
      } else {
        return {
          variant: 'secondary',
          size: 'xs'
        }
      }
    },
    activeCulture () {
      return this.cultures.find(c => c.active) || {}
    }
  }
}
</script>

<style lang="scss">
// mobile
$languagenavigation-toggle-font-weight: $font-weight-normal !default;

$languagenavigation-menu-padding-y: $spacer * 0.5 !default;
$languagenavigation-menu-padding-x: $grid-gutter-width * 0.5 !default;

// desktop
$languagenavigation-breakpoint: $desktop-breakpoint;

$languagenavigation-desktop-toggle-font-weight: $languagenavigation-toggle-font-weight !default;

.languagenavigation {
  @include media-breakpoint-down(get-breakpoint-key($languagenavigation-breakpoint, -1)) {
    width: 100%;

    .dropdown-toggle {
      font-weight: $languagenavigation-toggle-font-weight;
    }

    &:not(.is-static) {
      .dropdown-toggle {
        text-align: inherit;
        border-radius: 0;
        box-shadow: none;
      }

      .dropdown-menu {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $languagenavigation-menu-padding-y $languagenavigation-menu-padding-x;
        border: 0 none;
        border-radius: 0;

        .dropdown-header {
          padding-left: 0;
          padding-right: 0;
        }

        .dropdown-item {
          display: flex;
          justify-content: space-between;
          padding-left: 0;
          padding-right: 0;
          background-color: $dropdown-bg;
          color: $dropdown-link-color;
          border-radius: 0;
        }
      }
    }

    &.is-static {
      .dropdown-toggle:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  @include media-breakpoint-up($languagenavigation-breakpoint) {
    .dropdown-toggle {
      font-weight: $languagenavigation-desktop-toggle-font-weight;

      &:not(:hover):not(:active) {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
