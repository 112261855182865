<template>
    <b-icon :icon="icon" v-bind="$attrs" v-if="iconLoaded" />
</template>
<script>
import Vue from 'vue'
import { loadIcon } from '@/$plugins/bootstrap/icons/custom-icons'
import { normalizedIconNames } from '@/$plugins/bootstrap/icons'

export default {
  name: 'LazyIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iconLoaded: false
    }
  },
  mounted() {
    if (normalizedIconNames.includes(this.icon)) {
      this.iconLoaded = true
      return
    }

    this.$nextTick(() => {
      const iconData = loadIcon(this.icon)

      if (iconData) {
        Vue.component(iconData.extendOptions.name, iconData)
        this.iconLoaded = true
      } else {
        console.warn(`Icon '${this.icon}' not found`)
      }
    })
  }
}
</script>
