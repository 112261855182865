import { makeIcon } from 'bootstrap-vue/esm/icons/helpers/make-icon'
import string from '@/assets/js/helper/string'

export function loadIcon(name) {
  const iconName = string.pascalCase(name)
  const context = require.context('@/../public/icons', true, /\.svg$/)
  const key = context.keys().find(k => string.pascalCase(k.replace(/(^.\/|.svg$)/g, '')) === iconName)

  if (!key) {
    return null
  }

  const svgContentRegexp = /<svg[^>]+>((?:.|[\n\r\u2028\u2029])+)<\/svg>/gm
  const matches = svgContentRegexp.exec(context(key).default)
  const path = matches[1]
  const icon = makeIcon(name, path)

  return icon
}
